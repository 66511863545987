import React from "react";

const BlogStanderd = ({ blogs }) => {
  return (
    <section className="blog-pg section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="posts">
              {
                blogs.map((blogItem, index) => (
                  <div className={`item ${index === blogs.length - 1 ? "" : "mb-80"}`} key={blogItem.id}>
                    <div className="img">
                      <a href={blogItem.link} target="_blank" rel="noopener noreferrer">
                        <img src={blogItem.image} alt="" />
                      </a>
                    </div>
                    <div className="content">
                      <div className="row justify-content-center">
                        <div className="col-10">
                          {/* <a href={`/blog/blog-dark`} target="_blank" rel="noopener noreferrer" className="date">
                            <span className="num">{blogItem.date.day}</span>
                            <span>{blogItem.date.month}</span>
                          </a> */}
                          <div className="tags">
                            {
                              blogItem.tags.map((tag, index) => (
                                <a key={index} href={blogItem.link} target="_blank" rel="noopener noreferrer">
                                  {tag}
                                </a>
                              ))
                            }
                          </div>
                          <h4 className="title">
                            <a href={blogItem.link} target="_blank" rel="noopener noreferrer">
                              {blogItem.title}
                            </a>
                          </h4>
                          <p>{blogItem.content}</p>
                          <a href={blogItem.link} target="_blank" rel="noopener noreferrer" className="butn bord curve mt-30">
                           Go to Website
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              }
              {/* <div className="pagination">
                <span className="active">
                  <a href={`/blog/blog-dark`} target="_blank" rel="noopener noreferrer">1</a>
                </span>
                <span>
                  <a href={`/blog/blog-dark`} target="_blank" rel="noopener noreferrer">2</a>
                </span>
                <span>
                  <a href={`/blog/blog-dark`} target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-angle-right"></i>
                  </a>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogStanderd;
