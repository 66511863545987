import React from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { handleDropdown, handleMobileDropdown } from "common/navbar";

const Navbar = ({ lr, nr, theme }) => {
  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change ${
        theme === "themeL" ? "light" : ""
      }`}
    >
      <div className="container">
        <Link to="/homepage/home1-light" className="logo">
          {theme ? (
            theme === "themeL" ? (
              <img
                style={{
                  height: "50px",
                  width: "250px",
                }}
                ref={lr}
                src={appData.darkLogo}
                alt="logo"
              />
            ) : (
              <img
                style={{
                  height: "50px",
                  width: "250px",
                }}
                ref={lr}
                src={appData.lightLogo}
                alt="logo"
              />
            )
          ) : (
            <img
              style={{
                height: "50px",
                width: "250px",
              }}
              ref={lr}
              src={appData.lightLogo}
              alt="logo"
            />
          )}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileDropdown}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item"
              style={{
                color:'black'
              }}
            >
              <Link to={`/homepage/home1-light`} className="nav-link"
                style={{
                  color:'black'
                }}
              >
                Home
              </Link>
            </li>

            <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color:'black'
                }}
              >
                Professional Services
              </span>
              <div className="dropdown-menu">
                <Link to={`/homepage/iotadvisorypage`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  IoT Advisory
                </Link>
                <Link to={`/homepage/projectmanagement`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Project Management
                </Link>
                <Link to={`/homepage/designandarchitecture`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Design and Architecture
                </Link>
                <Link to={`/homepage/fieldandinstallation`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Field Installation
                </Link>
                <Link to={`/homepage/proofofconcept`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Proof Of Concept
                </Link>
                <Link to={`/homepage/sitesurvey`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Site Survey
                </Link>
                <Link to={`/homepage/provisioningstagingandonboarding`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                  Provisioning, Staging and Onboarding
                </Link>

                <Link to={`/homepage/bposervices`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                 BPO
                </Link>

                <Link to={`/homepage/softwaredevelopment`} className="dropdown-item"
                       style={{
                        color:'black'
                      }}
                >
                 Software Development
                </Link>
              </div>
            </li>

            <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  color:'black'
                }}
              >
                Managed Services
              </span>
              <div className="dropdown-menu">
                <Link to={`/homepage/supportdesk`} className="dropdown-item">
                  Support Desk
                </Link>
                <Link to={`/homepage/connectivityManagement`} className="dropdown-item">
                  Connectivity Management
                </Link>
              </div>
            </li>

            <li className="nav-item">
              <Link to={`/homepage/documentation`} className="nav-link"
                     style={{
                      color:'black'
                    }}
              >
                Documentation
              </Link>
            </li>

            <li className="nav-item">
              <Link to={`/homepage/partners`} className="nav-link"
                     style={{
                      color:'black'
                    }}
              >
                Partners
              </Link>
            </li>

            <li className="nav-item">
              <Link to={`/contact/contact-light`} className="nav-link"
                     style={{
                      color:'black'
                    }}
              >
                Contact Us
              </Link>
            </li>

            <li className="nav-item">
              <Link to={`/about/about-light`} className="nav-link"
                     style={{
                      color:'black'
                    }}
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
